<template>
    <el-drawer :title="title" :visible.sync="isShow" size="800px" :before-close="onClose" @opened="onOpened">
        <el-tabs v-model="activeTab">
            <el-tab-pane v-for="tab in tabList" :key="tab.val" :label="tab.name" :name="tab.val" />
        </el-tabs>
        <template v-if="activeItem">
            <Follow
                :activeItem="activeItem"
                :intentTypeMap="intentTypeMap"
                :editState="editState"
                v-if="activeTab === 'follow'"
                @update="onUpdate"
            />
            <UserCourse
                :activeItem="{ projectNo: projectNo, id: activeItem.oppoId }"
                :userId="activeItem.userId"
                v-if="activeTab === 'course'"
            />
            <UserOrder :activeItem="{ projectNo: projectNo, id: activeItem.oppoId }" v-if="activeTab === 'order'" />
        </template>
    </el-drawer>
</template>
<script>
import Follow from './Follow.vue'
import UserCourse from '../UserCourse.vue'
import UserOrder from '../UserOrder.vue'
import { oppoUserWechat } from '@/js/api/saleApi.js'
import { mapGetters } from 'vuex'

export default {
    props: ['isShow', 'activeItem', 'projectNo', 'intentTypeMap'],
    components: { UserCourse, UserOrder, Follow },
    computed: {
        ...mapGetters(['userInfo']),
        editState: function () {
            return this.activeItem && this.userInfo.id === this.activeItem.staffId
        },
    },
    data() {
        return {
            title: '',
            activeTab: 'follow',
            tabList: [
                { name: '跟进记录', val: 'follow' },
                { name: '公开课', val: 'course' },
                { name: '订单', val: 'order' },
            ],
            accountList: [],
        }
    },
    methods: {
        onOpened() {
            this.title = '意向详情'
            this.tableData = []
            if (this.activeItem) this.title = `${this.activeItem.userInfo.nickname} - 意向详情`

            this.activeTab = 'follow'
            this.updateState = false
            this.oppoUserWechatApi()
        },
        onClose() {
            this.accountList = []
            this.$emit('onClose', this.updateState)
        },

        onUpdate() {
            this.updateState = true
        },
        oppoUserWechatApi() {
            oppoUserWechat({
                oppoId: this.activeItem.id,
                projectNo: this.activeItem.projectNo,
            }).then((res) => {
                this.accountList = res.data
            })
        },
    },
}
</script>
<style lang="less" scoped>
/deep/ .title {
    font-size: 14px;
    font-weight: 700;
    line-height: 2em;
    height: 2em;
}
</style>
